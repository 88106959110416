import React from 'react'

// COMPONENTS
import DailyReportList from '../Components/Report/DailyReportList'

const DailyReport = () => {
  return (
     <DailyReportList/>
  )
}

export default DailyReport