// COMPONENTS
import RegisterForm from "../Components/Admin/RegisterForm"

const Register = () => {
 
      return<>
          <RegisterForm/>
      </>
}

export default Register